@import './variables';
@import './mixins';
@import './component/login';
@import './component/header';
@import './component/notifications';
@import './component/userbox';
@import './component/layout';
@import './component/suspenseloader';
@import './component/sidebar';
@import './component/app';
@import './font-style.scss';

//common classes goes here
body {
  padding: 0 !important;
}

#root {
  overflow: auto;
  @include commonScrollBar;
}

.cursorPointer {
  cursor: pointer;
}

.cursorDefault {
  cursor: default !important;
}

.hr-line {
  border: 1px solid $borderColor;
}

.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

.p-fixed {
  position: fixed;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//multiple file upload icon size

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

//Text Transform
.textUppercase {
  text-transform: uppercase !important;
}

.textCapitalize {
  text-transform: capitalize !important;
}
.textNone {
  text-transform: none !important;
}

//text align
.flex-basic-center {
  @include flex-basic-center;
}

.flex-column-start {
  @include flex-column-center;
  justify-content: start !important;
}

.flex-column-center {
  @include flex-column-center;
}

.flex-basic-start {
  justify-content: start !important;
  @include flex-basic-center;
}

.flex-basic-space-between {
  justify-content: space-between !important;
  @include flex-basic-center;
}

.flex-basic-space-around {
  justify-content: space-around !important;
  @include flex-basic-center;
}

.flex-basic-end {
  justify-content: end !important;
  @include flex-basic-center;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-column-end {
  display: flex;
  flex-direction: column !important;
  align-items: flex-end;
}

.flex-column-start {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: start;
}

.align-items-start {
  align-items: start !important;
}

.align-items-end {
  align-items: end !important;
}

.textDecorationNone {
  text-decoration: none !important;
}
.textweight {
  @include font-weight-bold;
}
.textalign {
  text-align: center;
}

//height
.h-100 {
  height: 100%;
}

.h-100vh {
  height: 100vh;
}

//width per clasess

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

//text size
.text-h1 {
  font-size: 32px !important;
  line-height: 54px !important;
  @include font-weight-semibold;
}

.text-h2 {
  font-size: 24px !important;
  line-height: 36px !important;
  @include font-weight-semibold;
}

.text-h3 {
  font-size: 18px !important;
  line-height: 28px !important;
  @include font-weight-semibold;
}

.text-h3-regular {
  font-size: 18px !important;
  line-height: 28px !important;
}

.text-large-bold {
  font-size: 16px !important;
  line-height: 24px !important;
  @include font-weight-bold;
}

.text-large-semibold {
  font-size: 16px !important;
  line-height: 24px !important;
  @include font-weight-semibold;
}

.text-large-medium {
  font-size: 16px !important;
  line-height: 24px !important;
  @include font-weight-medium;
}

.text-large-regular {
  font-size: 16px !important;
  line-height: 24px !important;
  @include font-weight-regular;
}

.text-medium-bold {
  font-size: 14px !important;
  line-height: 20px !important;
  @include font-weight-bold;
}

.text-medium-semibold {
  font-size: 14px !important;
  line-height: 20px !important;
  @include font-weight-semibold;
}

.text-medium-regular {
  font-size: 14px !important;
  line-height: 20px !important;
  @include font-weight-regular;
}

.text-small-semibold {
  font-size: 12px !important;
  line-height: 18px !important;
  @include font-weight-semibold;
}

.text-small-medium {
  font-size: 12px !important;
  line-height: 18px !important;
  @include font-weight-medium;
}

.text-small-regular {
  font-size: 12px !important;
  line-height: 18px !important;
  @include font-weight-regular;
}

.text-extra-small-regular {
  font-size: 10px !important;
  line-height: 16px !important;
  @include font-weight-regular;
}

//text colors
.text-black {
  color: rgba(29, 29, 29, 1);
}

.text-grey-1 {
  color: rgba(51, 51, 51, 1);
}

.text-grey-2 {
  color: rgba(79, 79, 79, 1);
}

.text-grey-3 {
  color: rgba(130, 130, 130, 1);
}

.text-grey-4 {
  color: rgba(189, 189, 189, 1);
}

.text-grey-5 {
  color: rgba(224, 224, 224, 1);
}

.border-color {
  color: rgba(215, 224, 226, 1);
}

//font weight
.text-bold {
  @include font-weight-bold;
}

.border-radius-8 {
  border-radius: 8px;
}

.text-semibold {
  @include font-weight-semibold;
}

.text-medium {
  @include font-weight-medium;
}

.text-regular {
  @include font-weight-regular;
}

.d-flex {
  display: flex;
}

.m-width {
  min-width: 350 !important ;
}

// Button Width Classes
.MuiButton-root {
  &.button-w-280 {
    min-width: 280px !important;
  }

  &.button-w-240 {
    min-width: 240px !important;
  }

  &.button-w-140 {
    min-width: 140px !important;
  }

  &.button-w-80 {
    min-width: 80px !important;
  }

  &.button-w-50 {
    min-width: 50px !important;
  }

  &.button-h-20 {
    min-height: 20px !important;
  }
}

//font styles
.font-weight-semibold {
  @include font-weight-semibold;
}

.sidebarwrapper {
  position: fixed;
  left: 0;
  top: 0;
}

.baselayout {
  flex: 1;
  height: 100%;
}

.selectformcontrol {
  width: 100%;
}

.overflow_auto {
  overflow: auto;
}
.flex_1 {
  flex: 1;
}
.overflow_x_hidden {
  overflow-x: hidden;
}
.alignitem {
  align-items: center;
}

.loginBox {
  position: relative;
  &::after {
    height: 100%;
    content: '';
    position: absolute;
    width: 1px;
    right: 0px;
    background-color: $borderBgColor;
  }
}

.commonListScroll {
  @include commonScrollBar;
  overflow: auto;
}

.text-primary {
  color: $primary !important;
}

.text-white {
  color: $white !important;
}

.customTable {
  .tablestyle {
    .MuiDataGrid-root {
      border-radius: 0;
    }
  }
}

.customField {
  .MuiInputBase-root {
    .MuiInputBase-input {
      padding: 12px;
    }
  }
}

.customDialog {
  .MuiPaper-root {
    width: 600px;
  }
}

.datePickerContainer {
  .MuiFormControl-root {
    width: 100%;
  }
}

.customerDetailsTable {
  .tablestyle {
    .MuiDataGrid-root {
      @include border-radius(10px);
    }
  }
}

.MuiDialogTitle-root {
  border-bottom: 1px solid $borderColor;
}

.readMoreTextContainer {
  display: inline;
  width: 100%;
}

.onboarding-right-section {
  background-color: $progressSuccess;
  color: $white;
  background-image: url('../assets/images/trellis_logo.png'),
    url('../assets/images/trellis_logo.png');
  background-repeat: no-repeat;
  background-position: left -55px top -50px, right -62px top -63px;
  height: 100%;
}

.customChip {
  .MuiChip-root {
    border-radius: 0;
    .MuiChip-deleteIcon {
      @extend .text-grey-2;
      width: 16px;
      height: 16px;
    }
  }
}

.identity-card-top-section {
  background: $primary;
  background-color: $primary;
  color: $white;
  background-image: url('../assets/images/trellis_logo.png');
  background-repeat: no-repeat;
  background-position: right -62px top -63px;
  height: 30%;
  z-index: 0;
}

.identity-card-bottom-section {
  background: $white;
  height: 70%;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  height: 80%;
  z-index: 1;
}

.identity-card-height {
  height: calc(100vh - 200px);
}

.custom-asterisk {
  color: $errorTextColor;
}
