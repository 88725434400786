//all varibles goes under this files

$borderColor: #ccc;
$borderBgColor: #eeeeee;
$backgroundColor: #edf2f3;
$scrollBarBgColor: rgba(105, 129, 155, 0.5);
$white: #fff;
$font-family-icomoon: 'icomoon';

//progress stats color
$progressError: #e55957;
$progressSuccess: #009f46;
$progressWarning: #f6841b;
$progressStrong: #ffc30b;

//progress status bg color with opacity
$progressErrorBg: rgba(229, 89, 87, 0.585);
$progressSuccessBg: rgba(0, 159, 69, 0.486);
$progressWarningBg: rgba(246, 133, 27, 0.545);
$progressStrongBg: rgba(255, 194, 11, 0.495);
$errorTextColor: #ff1943;

$white: #ffffff;
$primary: #219653;
