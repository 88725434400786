@import '../variables';

.large {
  display: inline-block;
}
.small {
  display: none;
}
.SidebarWrapper {
  position: relative;
  z-index: 7;
  height: 100%;
  padding-bottom: 68px;
}

.navItemActive {
  background: $primary;
  @include border-radius(8px);
}
